import { SubGameType } from "types/game";

import { listGameCrashGame } from "./list-game_crash-game";
import { listGameEsports } from "./list-game_e-sport";
import { listGameFishing } from "./list-game_fishing";
import { listGameLiveCasino } from "./list-game_live-casino";
import { listGameSabong } from "./list-game_sabong";
import { listGameSlots } from "./list-game_slots";
import { listGameSports } from "./list-game_sports";
import { listGameTableCard } from "./list-game_table-card";

export interface AllCategoryGameType {
  id: string;
  data: SubGameType[];
  pathname: string;
}

export const allCategoryGame: AllCategoryGameType[] = [
  {
    id: "LC",
    pathname: "/live",
    data: listGameLiveCasino,
  },
  {
    id: "SL",
    pathname: "/slots",
    data: listGameSlots,
  },
  {
    id: "SB",
    pathname: "/sports",
    data: listGameSports,
  },
  {
    id: "ES",
    pathname: "/e-sport",
    data: listGameEsports,
  },
  {
    id: "FH",
    pathname: "/fishing",
    data: listGameFishing,
  },
  {
    id: "BG",
    pathname: "/crash-game",
    data: listGameCrashGame,
  },
  {
    id: "CF",
    pathname: "/sabong",
    data: listGameSabong,
  },
  {
    id: "BG",
    pathname: "/table-card",
    data: listGameTableCard,
  },
];
