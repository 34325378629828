/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, message, Modal } from "antd";
import { PlayerBankFormType } from "api/bank/type";
import InputCustom from "components/base/InputCustom";
import WrapperForm from "components/base/Modal/WrapperForm";
import SelectCustom from "components/base/SelectCustom";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import _map from "lodash/map";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { selectUsername } from "store/auth";
import { selectBanks } from "store/bank";
import { addPlayerBank, getListBank } from "store/bank/bank.thunk";
import { setOpenModal } from "store/modal";
import { getWithdrawalForm } from "store/withdraw/withdraw.thunk";
import { BankType } from "types/bank";
import { ModalTypeValue } from "types/modal";
import * as yup from "yup";

import { AddBankStyle } from "./index.style";

const schema = yup.object({
  account_no: yup.string().required("This field is required"),
  account_name: yup.string().required("This field is required"),
  id: yup.string().required("Bank cannot be blank"),
  username: yup.string().required("This field is required"),
});

export const ModalAddBank = () => {
  const dispatch = useAppDispatch();

  const username = useAppSelector(selectUsername);
  const banks = useAppSelector(selectBanks);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const listOptionBank = useMemo(
    () =>
      _map(banks, (item: BankType) => {
        return {
          label: item.bank_name,
          value: item.bank_id,
        };
      }),
    [banks]
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PlayerBankFormType>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      username,
    },
  });

  useEffect(() => {
    if (!username) return;

    if (banks.length > 0) return;

    dispatch(getListBank(username));
  }, [username, banks]);

  const onSubmit = async (data: PlayerBankFormType) => {
    setIsLoading(true);

    const res = await dispatch(addPlayerBank(data));
    setIsLoading(false);

    const resData = res.payload;

    if (!resData) {
      message.error({ key: "withdraw", content: "Add bank player failed!!!" });
      return;
    }

    dispatch(getWithdrawalForm(username));
    message.success({ key: "withdraw", content: "Add bank player success!!!" });
    dispatch(setOpenModal(ModalTypeValue.Close));
    reset();
  };

  return (
    <Modal
      open={true}
      closable={false}
      onCancel={() => dispatch(setOpenModal(ModalTypeValue.Close))}
      css={AddBankStyle.modalContainer}
      footer={<></>}
      centered
    >
      <WrapperForm
        isBank
        title={"Add Bank"}
        body={
          <>
            <div>
              <Form
                className="deposit_form"
                onSubmitCapture={handleSubmit(onSubmit)}
              >
                <div
                  css={css`
                    margin: 12px 0;
                  `}
                >
                  <SelectCustom
                    control={control}
                    disabled={isLoading}
                    name={"id"}
                    options={listOptionBank}
                    placeholder="Select Bank"
                    validate_status={errors.id && "error"}
                    error={errors.id?.message}
                  />
                </div>

                <InputCustom
                  control={control}
                  name="account_no"
                  errors={errors.account_no?.message}
                  validate_status={errors.account_no && "error"}
                  margin_bottom="12px"
                  placeholder="Bank account number"
                />

                <InputCustom
                  control={control}
                  name="account_name"
                  errors={errors.account_name?.message}
                  validate_status={errors.account_name && "error"}
                  placeholder="Bank account name"
                />

                <div>
                  <Button
                    loading={isLoading}
                    css={css`
                      width: 100%;
                      background-color: #ffb31a;
                      border-color: transparent;
                      height: 38px;
                      font-size: 16px;

                      &:hover {
                        color: black !important;
                        border-color: transparent !important;
                        transform: scale(1.05);
                      }
                    `}
                    htmlType="submit"
                  >
                    Add bank
                  </Button>
                </div>
              </Form>
            </div>
          </>
        }
      />
    </Modal>
  );
};
