/** @jsxImportSource @emotion/react */

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Modal, notification } from "antd";
import { AuthApi } from "api/auth";
import { ChangePasswordFormType } from "api/auth/type";
import InputCustom from "components/base/InputCustom";
import { REGEX_PASSWORD } from "contants/regex";
import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { selectUsername } from "store/auth";
import { setOpenModal } from "store/modal";
import { ModalTypeValue } from "types/modal";
import * as yup from "yup";

import { ChangePasswordModalStyle } from "./style.index";

const validateForm = yup
  .object({
    username: yup.string().required(),
    oldpass: yup
      .string()
      .required("Field is required")
      .matches(REGEX_PASSWORD, "Includes alphanumeric and 6 to 15 characters")
      .required("Includes alphanumeric and 6 to 15 characters"),

    password: yup
      .string()
      .required("Field is required")
      .matches(REGEX_PASSWORD, "Includes alphanumeric and 6 to 15 characters")
      .required("Includes alphanumeric and 6 to 15 characters"),
    passconf: yup
      .string()
      .required("Field is required")
      .oneOf([yup.ref("password")], "Your password do not match")
      .matches(REGEX_PASSWORD, "Includes alphanumeric and 6 to 15 characters")
      .required("Includes alphanumeric and 6 to 15 characters"),
  })
  .required();

const ChangePasswordModal = () => {
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);

  const [api, contextHolder] = notification.useNotification();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<ChangePasswordFormType>({
    defaultValues: {
      username,
      oldpass: "",
      password: "",
      passconf: "",
    },
    mode: "all",
    resolver: yupResolver(validateForm),
  });

  const openNotification = (message: string, status: "success" | "error") => {
    api[status]({
      message,
      description: "",
    });
  };

  const onSubmit = async (data: ChangePasswordFormType) => {
    setLoading(true);

    const { msg, status } = await AuthApi.changePassword(username, {
      ...data,
      username,
    });

    setLoading(false);

    if (status === ResponseCode.SUCCESS) {
      openNotification(msg, "success");
      reset();
      return;
    } else {
      openNotification(msg, "error");
      return;
    }
  };
  return (
    <Modal
      open={true}
      forceRender
      title="Edit login password"
      onCancel={() => dispatch(setOpenModal(ModalTypeValue.Close))}
      footer={false}
      centered
      focusTriggerAfterClose={false}
      css={ChangePasswordModalStyle.wrapper}
    >
      <div css={ChangePasswordModalStyle.formWrapper}>
        <div css={ChangePasswordModalStyle.describeFrom}>
          If you log in to the site through your mobile phone number or social
          platform, please contact the customer service directly to assist the
          settings
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label>Current password</label>
          <InputCustom
            control={control}
            type="password"
            value={watch("oldpass")}
            name="oldpass"
            placeholder="Current password"
            errors={errors.oldpass?.message}
            validate_status={errors.oldpass ? "error" : "success"}
          />
          <label>Set new password</label>
          <InputCustom
            control={control}
            type="password"
            name="password"
            value={watch("password")}
            placeholder="New password"
            validate_status={errors.password ? "error" : "success"}
            errors={errors.password?.message}
          />
          <label>Confirm new password</label>
          <InputCustom
            control={control}
            name="passconf"
            type="password"
            value={watch("passconf")}
            placeholder="Confirm new password"
            validate_status={errors.passconf ? "error" : "success"}
            errors={errors.passconf?.message}
          />
          <Button
            loading={loading}
            disabled={!isDirty}
            htmlType="submit"
            type="primary"
          >
            Confirm
          </Button>
          {contextHolder}
        </form>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
