import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const CardGameStyle = {
  wrapper: css`
    position: relative;
    transition: 0.3s;

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50px;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #0009;

      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      cursor: pointer;
    }
    &:hover {
      .overlay {
        opacity: 1;
        visibility: visible;
        transition: 0.3s;
        svg {
          transform: scale(1.2);
          transition: transform 0.34s ease-in-out;
        }
      }
      [data-comp="hover-eff"] {
        opacity: 0.5;
        transition: 0.3s;

        h3 {
          color: white;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
          transition: 0.3s;
        }
      }
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50px;
    }
  `,

  textBox: css`
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 5px;

    text-align: start;

    padding: 0 20px 20px 20px;
    cursor: pointer;

    h3 {
      transition: 0.3s;
      color: white;
      max-width: 140px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
      font-size: 20px;
      font-style: normal;
      ${media.sm} {
        font-size: 12px;
        line-height: unset;
      }
    }
    span {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      ${media.sm} {
        font-size: 10px;
        line-height: unset;
      }
    }
  `,
};
