/** @jsxImportSource @emotion/react */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Modal } from "antd";
import { AuthApi } from "api/auth";
import { ForgotPasswordFormType } from "api/auth/type";
import InputCustom from "components/base/InputCustom";
import InputPhoneNumber from "components/base/InputPhoneNumber";
import { REGEX_USERNAME } from "contants/regex";
import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { selectIsLoading } from "store/auth";
import { setOpenModal } from "store/modal";
import { outletContextType } from "types";
import { ApiResponse } from "types/api";
import { ModalTypeValue } from "types/modal";
import * as yup from "yup";

import WrapperForm from "../WrapperForm";
import { ForgetPasswordStyle } from "./index.style";
const schema = yup
  .object({
    username: yup
      .string()
      .trim()
      .min(6, "Includes lowercase and 6 to 16 characters")
      .max(16, "Includes lowercase and 6 to 16 characters")
      .matches(REGEX_USERNAME, "Includes lowercase and 6 to 16 characters")
      .required(),
    mobile: yup.string().trim().required(),
    email: yup.string().trim().email().required(),
  })
  .required();
function ForgetPasswordModal() {
  const { openNotification } = useOutletContext<outletContextType>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordFormType>({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const [dataPassword, setDataPassword] = useState<string>("");

  const onSubmit = async (data: ForgotPasswordFormType) => {
    const datForm = {
      ...data,
      mobile: data.mobile.replace("+", ""),
    };
    const res = await AuthApi.forgotPassword(datForm.username, datForm);

    const dataResult = res.data as ApiResponse;

    if (dataResult.status !== ResponseCode.SUCCESS) {
      openNotification("error", dataResult.msg);
      return;
    }

    openNotification("success", "Recovery Success!");
    setDataPassword(dataResult.msg);
  };
  return (
    <Modal
      open={true}
      closable={false}
      forceRender
      onCancel={() => dispatch(setOpenModal(ModalTypeValue.Close))}
      css={ForgetPasswordStyle.modalContainer}
      footer={<></>}
      centered
      focusTriggerAfterClose={false}
    >
      <WrapperForm
        title={"Forget Password"}
        formType={ModalTypeValue.Login}
        body={
          <div css={ForgetPasswordStyle.formContainer}>
            <Form
              css={ForgetPasswordStyle.formContainer}
              onSubmitCapture={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <div css={ForgetPasswordStyle.inputControl}>
                <label>Username</label>
                <InputCustom
                  disabled={isLoading}
                  placeholder={"Username"}
                  name={"username"}
                  control={control}
                  errors={errors.username?.message}
                  margin_bottom="8px"
                  validate_status={errors.username ? "error" : "success"}
                />
              </div>
              <div css={ForgetPasswordStyle.inputControl}>
                <label>Email</label>
                <InputCustom
                  disabled={isLoading}
                  placeholder={"Email"}
                  name={"email"}
                  control={control}
                  errors={errors.email?.message}
                  margin_bottom="8px"
                  validate_status={errors.email ? "error" : "success"}
                />
              </div>
              <div css={ForgetPasswordStyle.inputControl}>
                <label>Mobile</label>
                <InputPhoneNumber
                  disabled={isLoading}
                  name={"mobile"}
                  control={control}
                  margin_bottom="8px"
                  error={errors.mobile?.message}
                  validate_status={errors.mobile ? "error" : "success"}
                  onChange={(val) => {
                    //
                  }}
                />
              </div>
              {dataPassword && (
                <div css={ForgetPasswordStyle.dataPassword}>
                  <span>{dataPassword}</span>
                </div>
              )}
              <Button
                disabled={!isValid}
                loading={isLoading}
                css={[
                  ForgetPasswordStyle.buttonSubmit,
                  isValid && ForgetPasswordStyle.buttonActive,
                ]}
                htmlType="submit"
              >
                {"Submit"}
              </Button>
            </Form>
          </div>
        }
        footer={
          <div css={ForgetPasswordStyle.footer}>
            <p>
              Already have an account?{" "}
              <span
                onClick={() => dispatch(setOpenModal(ModalTypeValue.Login))}
              >
                Go to login
              </span>
            </p>
          </div>
        }
      />
    </Modal>
  );
}

export default ForgetPasswordModal;
