import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const FooterStyle = {
  container: css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: ${theme.colors.primary[900]};
    padding-top: 1.5rem;
    ${media.md} {
      flex-direction: column;
      align-items: center;
      padding-bottom: 72px;
    }
    ${media.sm} {
      padding-bottom: unset;

      margin-bottom: 70px;
    }
  `,
  footerLeft: css`
    flex: 1;
  `,
  groupBox: css`
    display: flex;
    flex-direction: column;
    padding-left: 4rem;
    ${media.lg} {
      align-items: center;
      margin-bottom: 1.5rem;
      padding: 0 16px;
    }
    ${media.sm} {
      margin-bottom: 10px;
    }

    h3 {
      font-weight: 400;
      font-size: ${theme.fontSize[20]};
      line-height: 30px;
      ${media.lg} {
        font-size: 16px;
      }
    }

    a {
      text-decoration: none;
      font-weight: 400;
      font-size: ${theme.fontSize[14]};
      color: ${theme.colors.primary[100]};
      margin-top: 0.62rem;
      line-height: 24px;
      ${media.lg} {
        font-size: 12px;
      }
    }
  `,
  footerRight: css`
    flex: 2;
    padding-right: 2.5rem;
    display: flex;
    flex-direction: column;

    ${media.lg} {
      align-items: center;
      padding: 0 16px;
    }
  `,
  provider: css`
    ${media.md} {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    h3 {
      font-weight: 400;
      font-size: ${theme.fontSize[20]};
      line-height: 30px;
      ${media.sm} {
        font-size: 16px;
      }
    }
    .list-provider {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
      ${media.md} {
        justify-content: center;
      }

      .item-provider {
        width: 50px;
        height: 50px;
        margin-right: 2px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  `,
  boxRow: css`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    ${media.md} {
      margin: 1.5rem 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    ${media.sm} {
      margin: 0.5rem 0;
    }
    h3 {
      font-weight: 400;
      font-size: ${theme.fontSize[20]};
      line-height: 30px;

      ${media.md} {
        margin: 8px 0;
        text-align: center;
      }
      ${media.sm} {
        font-size: 16px;
      }
    }
    .partners {
      ${media.md} {
        display: none;
      }
      .list-partners {
        margin-top: 0.62rem;

        ${media.md} {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          width: 45px;
          object-fit: contain;
        }
      }
    }
    .support {
      /* margin-left: 4rem; */
      display: flex;
      flex-direction: column;
    }
  `,
  partnersMobile: {
    index: () => css`
      display: none;

      ${media.md} {
        display: block;
      }
    `,
  },

  copyRight: css`
    width: 100%;
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: 14px;
      line-height: 22px;
      color: ${theme.colors.primary[100]};
      font-weight: 400;
      ${media.sm} {
        font-size: 12px;
      }
    }
  `,
};
