/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, message } from "antd";
import { withdrawApi } from "api/withdraw";
import InputCustom from "components/base/InputCustom";
import { ResponseCode } from "contants/response";
import { useAppSelector } from "hooks/app-hook";
import { ButtonAmout } from "pages/TradingPage/components/ButtonAmount";
import { StepControl } from "pages/TradingPage/components/StepControl";
import { NextButton } from "pages/TradingPage/components/StepControl/NextButton";
import { PreviousButton } from "pages/TradingPage/components/StepControl/PreviousButton";
import { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { selectUsername } from "store/auth";
import { selectBalance } from "store/user";
import {
  selectDataWithdraw,
  selectDataWithdrawalSubMission,
} from "store/withdraw";
import { media } from "styles/breakpoints";
import * as yup from "yup";

import { AmountStepStyle } from "./index.style";

interface Props {
  setStep: Dispatch<SetStateAction<"amount" | "wallet">>;
}

export interface WithdrawalSubMissionForm {
  amount: number;
}

const listPercentage = [25, 50, 75, 100];

export const AmountStep = (props: Props) => {
  const { setStep } = props;

  const dataWithdraw = useAppSelector(selectDataWithdraw);
  const username = useAppSelector(selectUsername);
  const dataWithdrawalSubMission = useAppSelector(
    selectDataWithdrawalSubMission
  );
  const memberBalance = useAppSelector(selectBalance);

  const [selectValuePercentage, setSelectValuePercentage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const schema = () =>
    yup.object({
      amount: yup
        .number()
        .max(
          dataWithdraw.setting.max_withdrawal,
          `Max withdraw amount is ${dataWithdraw.setting.max_withdrawal}`
        )
        .min(
          dataWithdraw.setting.min_withdrawal,
          `Min withdraw amount is ${dataWithdraw.setting.min_withdrawal}`
        )
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("This field is required"),
    });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm<WithdrawalSubMissionForm>({
    mode: "onChange",
    resolver: yupResolver(schema()),
    defaultValues: {
      amount: 0,
    },
  });

  const onSubmit = async (data: WithdrawalSubMissionForm) => {
    message.loading({
      key: "withdraw",
      content: "Withdraw is loading...",
      duration: 20,
    });
    setIsLoading(true);
    const response = await withdrawApi.withdrawalSubmission(username, {
      username,
      ...data,
      ...dataWithdrawalSubMission,
    });
    setIsLoading(false);

    const { status } = response.data;

    if (status !== ResponseCode.SUCCESS) {
      message.error({
        key: "withdraw",
        content: "Withdrawal failed!",
      });
      return;
    }

    message.success({
      key: "withdraw",
      content: "Withdrawal success!",
    });
    reset();
  };

  return (
    <div css={AmountStepStyle.index}>
      <div css={AmountStepStyle.title}>Withdrawal amount</div>

      <div
        css={css`
          color: white;
          font-size: 15px;
          letter-spacing: 0.2px;
        `}
      >
        {`Withdrawal amount range ${dataWithdraw.setting.min_withdrawal} $ ~ ${dataWithdraw.setting.max_withdrawal} $`}
      </div>

      <Form
        css={css`
          width: 100%;
        `}
      >
        <div
          css={css`
            margin-top: 14px;
            width: 70%;
          `}
        >
          <InputCustom
            control={control}
            name="amount"
            errors={errors.amount?.message}
            validate_status={errors.amount && "error"}
            placeholder="Amount"
          />
        </div>

        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: 20px;

            ${media.md} {
              grid-template-columns: repeat(7, 1fr);
            }

            ${media.sm} {
              grid-template-columns: repeat(4, 1fr);
            }
          `}
        >
          {listPercentage.map((value: number) => (
            <ButtonAmout
              key={value}
              value={value}
              selectValuePercentage={selectValuePercentage}
              onClick={() => {
                setSelectValuePercentage(value);
                setValue(
                  "amount",
                  (Number(memberBalance?.total) * value) / 100
                );
              }}
            />
          ))}
        </div>

        <StepControl
          renderPreviousStep={() => (
            <PreviousButton onBackClick={() => setStep("wallet")} />
          )}
          renderNextStep={() => (
            <NextButton
              onNextClick={handleSubmit(onSubmit)}
              loading={isLoading}
              text="Complete"
            />
          )}
        />
      </Form>
    </div>
  );
};
