/** @jsxImportSource @emotion/react */

import { ErrorImage } from "contants/config";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { handleLoginGame } from "pages/Game/helpers/handleLoginGame";
import { selectUsername } from "store/auth";
import { SubGameType } from "types/game";

import { SubGameItemStyle } from "./style.index";

interface Props {
  subGame: SubGameType;
}

export const SubGameItem = (props: Props) => {
  const { subGame } = props;

  const dispatch = useAppDispatch();

  const username = useAppSelector(selectUsername);

  return (
    <div
      css={SubGameItemStyle.wrapper}
      key={subGame.game_code}
      onClick={() => handleLoginGame(subGame, username, dispatch)}
    >
      <img
        loading="lazy"
        onError={(e) => {
          (e.target as HTMLImageElement).src = ErrorImage;
        }}
        src={subGame.picture}
        alt={subGame.name}
      />
    </div>
  );
};

export default SubGameItem;
