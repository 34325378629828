import bgSlots from "assets/images/bg_slots.png";
import bgLive from "assets/images/live_bg.png";
import bgSports from "assets/images/sports_bg.png";

export interface CategoryGameType {
  id: string;
  name: string;
  path: string;
  provider?: string;
  background: string;
}

export const LIST_CATEGORY_GAME: CategoryGameType[] = [
  {
    id: "LC",
    name: "Live",
    path: "/live",
    background: bgLive,
  },
  {
    id: "SL",
    name: "Slots",
    path: "/slots",
    background: bgSlots,
  },

  {
    id: "SB",
    name: "Sports",
    path: "/sports",
    background: bgSports,
  },
];
