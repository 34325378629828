import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { message } from "antd";
import { GameApi } from "api/game";
import { Logo } from "assets/images";
import axios, { CancelTokenSource } from "axios";
import { ResponseCode } from "contants/response";
import { setOpenModal } from "store/modal";
import { SubGameType } from "types/game";
import { ModalTypeValue } from "types/modal";

let source: CancelTokenSource;
let openBlank: Window | null = null; // Variable to store the reference to the opened window
export const isMobile = /iPhone|iPad|iPod|Android|tablet/i.test(
  navigator.userAgent
);

export const handleLoginGame = async (
  dataLogin: SubGameType,
  username: string,
  dispatch: Dispatch<AnyAction>
) => {
  if (!username) {
    dispatch(setOpenModal(ModalTypeValue.Login));
    return;
  }

  message.loading({
    key: "Game",
    content: "Game is loading...",
    duration: 20,
  });

  if (source) {
    source.cancel("Operation canceled due to new request.");
  }

  source = axios.CancelToken.source();

  if (isMobile) {
    try {
      const response = await GameApi.loginGame(
        username,
        {
          username,
          game_type: dataLogin.type,
          game_code: dataLogin.game_code,
          provider: dataLogin.provider_code,
          source,
        },
        isMobile
      );
      const dataLoginGame = response.data;
      if (dataLoginGame.status !== ResponseCode.SUCCESS) {
        message.error({
          key: "Game",
          content: "The game is under maintenance, please try again later!",
        });
        return;
      }

      message.success({
        key: "Game",
        content: "Login game is success!",
      });
      window.location.href = dataLoginGame.url;
    } catch (error) {
      if (
        (error as { message: string }).message.includes(
          "Operation canceled due to new request."
        )
      ) {
        return;
      }

      message.error({
        key: "Game",
        content: "Server is under maintenance, please try again later!",
      });
    }
    return;
  }

  if (openBlank && !openBlank.closed) {
    openBlank.close();
  }

  openBlank = window.open(
    "",
    "LoginGame",
    `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=600,left=200,top=-1000`
  );

  if (!openBlank) return;

  openBlank.document.write(`
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Loading...</title>
        <style>
          .loader { height: 100%; width: 100%; display: flex; justify-content: center; align-items: center; position: fixed; top: 0; left: 0; background: #333333; z-index: 999999 }
          .loader-image { width: 16rem; object-fit: contain; animation: scaleImage 2s ease infinite }
          @keyframes scaleImage {
            0% { transform: scale(1) }
            50% { transform: scale(1.2) }
            100% { transform: scale(1) }
          }
        </style>
      </head>
      <body>
        <div class="loader">
          <img class="loader-image" src="${Logo}" alt="Loading..." />
        </div>
      </body>
    </html>
  `);

  try {
    const response = await GameApi.loginGame(
      username,
      {
        username,
        game_type: dataLogin.type,
        game_code: dataLogin.game_code,
        provider: dataLogin.provider_code,
        source,
      },
      isMobile
    );

    const dataLoginGame = response.data;

    if (dataLoginGame.status !== ResponseCode.SUCCESS) {
      message.error({
        key: "Game",
        content: "The game is under maintenance, please try again later!",
      });
      openBlank.close();
      return;
    }

    message.success({
      key: "Game",
      content: "Login game is success!",
    });

    openBlank.location.href = dataLoginGame.url;
  } catch (error) {
    if (
      (error as { message: string }).message.includes(
        "Operation canceled due to new request."
      )
    ) {
      return;
    }

    message.error({
      key: "Game",
      content: "Server is under maintenance, please try again later!",
    });
  }
};
