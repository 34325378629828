/** @jsxImportSource @emotion/react */

import { LinkDownloadFileApk } from "contants/apk";
import { arrayProvider } from "contants/provider";
import { Link } from "react-router-dom";

import { FooterStyle } from "./index.style";

const Footer = () => {
  return (
    <>
      <div css={FooterStyle.container}>
        <div css={FooterStyle.footerLeft}>
          <div css={FooterStyle.groupBox}>
            <h3>BETCENTER</h3>
            <Link target="_blank" to={LinkDownloadFileApk}>
              Download APP
            </Link>
            <Link to={"#"}>About</Link>
          </div>
          <div style={{ marginTop: "16px" }} css={FooterStyle.groupBox}>
            <h3>Terms & Policies</h3>
            <Link to={"#"}>Anti money laundering policy</Link>
            <Link to={"#"}>Privacy Policy</Link>
            <Link to={"#"}>User Agreement</Link>
            <Link to={"#"}>Terms of Service</Link>
          </div>
        </div>
        <div css={FooterStyle.footerRight}>
          <div css={FooterStyle.provider}>
            <h3>Provider</h3>
            <div className="list-provider">
              {arrayProvider.map((item, index) => {
                return (
                  <Link
                    key={`provider-item-${index}`}
                    to={item.link}
                    className="item-provider"
                  >
                    <img src={item.icon} alt="" />
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        <div css={FooterStyle.copyRight}>
          <h3>COPYRIGHT © 2023 BETCENTER All Rights Reserved.</h3>
        </div>
      </div>
    </>
  );
};

export default Footer;
