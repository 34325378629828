/** @jsxImportSource @emotion/react */

import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { Empty, Pagination } from "antd";
import { GameApi } from "api/game";
import { SubGameResponse } from "api/game/type";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import HelmetCustom from "components/ui/Helmet";
import { ListProviderStyle } from "components/ui/ListProvider/style.index";
import Loading from "components/ui/Loading";
import SubGameItem from "components/ui/SubGameItem";
import { listSort } from "contants/list-hard";
import { ResponseCode } from "contants/response";
import { useDebounce } from "hooks/useDebounce";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubGameType } from "types/game";

import { useCategoryGameDetail } from "../hooks/useCategoryDetail";
import { SubGamePageStyle } from "./index.style";

const PAGE_SIZE = 48;

const SubGamePage = () => {
  const inputRef = useRef<HTMLInputElement | any>(null);
  const { categoryName = " ", providerId = "" } = useParams();

  const navigate = useNavigate();

  const categoryGameDetail = useCategoryGameDetail(categoryName);

  const [data, setData] = useState<SubGameResponse>();
  const [category, setCategory] = useState<string>();

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSort] = useState<string>("all");
  const [search, setSearch] = useDebounce("");

  const getDataGameList = async () => {
    try {
      const res = await GameApi.getGameList(categoryGameDetail.id);

      const { data, status } = res.data;

      if (status === ResponseCode.SUCCESS) {
        const itemFind = data.find((item) => item.provider_code === providerId);
        setCategory(itemFind?.provider_name);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getDataSubGame = async () => {
    try {
      setIsLoading(true);
      setData(undefined);
      const res = await GameApi.getSubGame({
        provider: providerId,
        game_type: categoryGameDetail.id,
        page: currentPage,
        size: PAGE_SIZE,
        featured: sort === "all" ? 0 : 1,
        search,
      });

      const { status } = res.data;

      if (status === ResponseCode.SUCCESS) {
        setData(res.data);
      } else {
        setData(undefined);
      }

      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const renderListSubGame = () => {
    if (!dataSubGame?.length)
      return (
        <div css={SubGamePageStyle.notFound}>
          <Empty css={SubGamePageStyle.empty} />
        </div>
      );

    return (
      <div css={SubGamePageStyle.wrapperListSubGame}>
        {dataSubGame.map((subGame: SubGameType, index: number) => (
          <SubGameItem
            subGame={subGame}
            key={`${subGame.game_code}_${index}`}
          />
        ))}
      </div>
    );
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value);
  };

  const onResetSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      setSearch("");
    }
  };

  const onChangeFilter = (idSort: string) => {
    setSort(idSort);
    setCurrentPage(1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  useEffect(() => {
    getDataSubGame();
  }, [providerId, sort, currentPage, search]);

  useEffect(() => {
    getDataGameList();
    setSort("all");
    setCurrentPage(1);
  }, [providerId]);

  const dataSubGame = data?.data;
  const totalCountSubGame = data?.totalCount;

  return (
    <div css={SubGamePageStyle.wrapper}>
      <div css={SubGamePageStyle.container}>
        <HelmetCustom title={categoryGameDetail.name} />
        <div css={SubGamePageStyle.heading}>
          <ArrowIcon
            css={SubGamePageStyle.arrow}
            onClick={() => navigate(-1)}
          />{" "}
          <p css={SubGamePageStyle.title}>
            {categoryName.toUpperCase()} {category && "/"} {category}
          </p>
        </div>

        <div css={SubGamePageStyle.inputControl}>
          <input
            ref={inputRef}
            key={"input-search"}
            onChange={onChange}
            defaultValue={search}
            placeholder="Search"
            css={SubGamePageStyle.input}
          />
          <SearchOutlined css={SubGamePageStyle.searchIcon} />
          {!!search && (
            <CloseOutlined
              css={SubGamePageStyle.clearIcon}
              onClick={onResetSearch}
            />
          )}
        </div>

        <div css={SubGamePageStyle.blockSort}>
          <div css={ListProviderStyle.gameType.index}>
            <div css={ListProviderStyle.title}>Sort by</div>
            <div css={ListProviderStyle.gameType.list}>
              {listSort.map((item, index) => {
                const isActive = item.id === sort;
                return (
                  <div key={index}>
                    <div
                      onClick={() => onChangeFilter(item.id)}
                      css={SubGamePageStyle.filter(isActive)}
                    >
                      <span>{item.name}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {isLoading ? (
          <Loading height={500} width={"100%"} center />
        ) : (
          <React.Fragment>
            {renderListSubGame()}

            {dataSubGame && dataSubGame.length > 0 && (
              <div css={SubGamePageStyle.boxPagination}>
                <Pagination
                  css={SubGamePageStyle.paginationStyle}
                  current={currentPage}
                  pageSize={PAGE_SIZE}
                  total={totalCountSubGame}
                  onChange={(page: number) => {
                    setCurrentPage(page);
                  }}
                  showSizeChanger={false}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default SubGamePage;
