/** @jsxImportSource @emotion/react */
import "react-phone-number-input/style.css";

import { Form } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import { Control, Controller, FieldValues } from "react-hook-form";
import PhoneInput, {
  isValidPhoneNumber,
  Value,
} from "react-phone-number-input";

import { InputPhoneNumberStyle } from "./index.style";
interface SelectCustomProps {
  name: string;
  control?: Control<FieldValues | any>;
  error?: string;
  validate_status?: ValidateStatus;
  value?: string;
  disabled?: boolean;
  onChange: (value: Value) => void;
  margin_bottom?: string;
}

const InputPhoneNumber = (props: SelectCustomProps) => {
  const { control, error, validate_status, margin_bottom } = props;
  return (
    <div>
      {control ? (
        <Form.Item
          style={{ marginBottom: margin_bottom ? margin_bottom : "0px" }}
          help={error}
          validateStatus={validate_status}
        >
          <div css={InputPhoneNumberStyle.inputContainer}>
            <Controller
              name={props.name}
              control={control}
              rules={{
                required: true,
                validate: (value) => isValidPhoneNumber(value),
              }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  value={value as string}
                  onChange={onChange}
                  defaultCountry={"TH"}
                  id={props.name}
                  disabled={props.disabled}
                  css={InputPhoneNumberStyle.inputPhoneNumber}
                  numberInputProps={{
                    placeholder: "Phone Number",
                  }}
                />
              )}
            />
          </div>
        </Form.Item>
      ) : (
        <PhoneInput
          defaultCountry={"TH"}
          id={props.name}
          css={InputPhoneNumberStyle.inputPhoneNumber}
          numberInputProps={{
            placeholder: "Phone Number",
          }}
          value={props.value}
          onChange={props.onChange}
        />
      )}
    </div>
  );
};

export default InputPhoneNumber;
