/** @jsxImportSource @emotion/react */

import { Modal } from "antd";
import { ErrorImage } from "contants/config";
import { useState } from "react";
import { PromotionListType } from "types/promotions";

import { CardPromotionStyle } from "./style.index";

const CardPromotion = (props: PromotionListType) => {
  const { bonus, wager, web, content } = props;
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  return (
    <div css={CardPromotionStyle.wrapper}>
      <img
        src={web}
        alt=""
        onError={(e) => {
          (e.target as HTMLImageElement).src = ErrorImage;
        }}
      />
      <div css={CardPromotionStyle.textBox}>
        <h4>{bonus}</h4>
        <p>
          Wager <span>x{wager}</span>
        </p>
        <div css={CardPromotionStyle.btnDetail.index}>
          <div
            onClick={() => setIsOpenModal(true)}
            css={CardPromotionStyle.btnDetail.animate}
          >
            <span>Details</span>
            <div className="arrow">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <Modal
        onCancel={() => setIsOpenModal(false)}
        centered
        open={isOpenModal}
        footer={null}
        css={CardPromotionStyle.modal}
      >
        <>
          <div css={CardPromotionStyle.bonusModal}>{bonus}</div>
          <div
            css={CardPromotionStyle.contentModal}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </>
      </Modal>
    </div>
  );
};

export default CardPromotion;
