import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const DepositStyle = {
  index: () => css`
    background: rgba(16, 21, 32, 0.8);
    border-radius: 8px;
  `,
  content: css`
    min-height: calc(100vh - 47px - 62px - 69px - 16px);
  `,
  boxOptions: css`
    display: flex;
    flex-direction: column;
    padding: 12px;
    ${media.md} {
      button {
        width: 50%;
      }
    }
    ${media.sm} {
      button {
        width: 100%;
      }
    }
    h3 {
      color: ${theme.colors.primary[100]};
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }
  `,
  listOptions: css`
    margin-top: 13.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  `,
  itemOptions: (isActive: boolean) => css`
    padding: 11px 9px;
    border-radius: 10px;
    border: 1px solid
      ${isActive ? `rgba(255, 179, 26, 0.8)` : theme.colors.primary[500]};
    background: rgba(61, 70, 104, 0.3);
    cursor: pointer;
    margin-right: 8px;
    min-width: 120px;
    margin-bottom: 6px;
    ${media.sm} {
      min-width: 150px;
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 37px;
        height: 37px;
      }

      span {
        color: ${isActive ? theme.colors.secondary[700] : theme.colors.white};
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin-top: 6px;
      }
    }
  `,
  formInput: css`
    margin-top: 13.5px;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${media.md} {
      flex-direction: column;
      align-items: flex-start;
    }
  `,
  inputNumber: css`
    border-radius: 10px;
    border: 1px solid #3d4668;
    background: #101520;
    color: rgba(255, 255, 255, 0.5);
    min-width: 300px;

    .ant-input-number-input {
      color: ${theme.colors.white} !important;
      height: 44px;
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  `,
  listCurrencies: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 12px;
    ${media.md} {
      margin-left: 0;
      margin-top: 8px;
    }
  `,
  itemCurrency: (isActive: boolean) => css`
    padding: 10px 12px;
    background: rgba(255, 255, 255, 0.15);
    margin-right: 8px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid
      ${isActive ? `rgba(255, 179, 26, 0.8)` : theme.colors.primary[500]};
    span {
      color: ${isActive
        ? theme.colors.secondary[700]
        : theme.colors.primary[100]};
    }
  `,
  itemPromotion: (isActive: boolean) => css`
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.15);
    padding: 6px 12px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    border: 1px solid
      ${isActive ? `rgba(255, 179, 26, 0.8)` : theme.colors.primary[500]};
    span {
      color: ${isActive
        ? theme.colors.secondary[700]
        : theme.colors.primary[100]};
    }
  `,
};
