/** @jsxImportSource @emotion/react */

import { SyncOutlined } from "@ant-design/icons";
import { Button, Image, message } from "antd";
import { ReactComponent as CloseIconSvg } from "assets/icons/closeIcon.svg";
import { ReactComponent as CopyIconSvg } from "assets/icons/copyString.svg";
import { ReactComponent as DownIcon } from "assets/icons/icon-arrow-left.svg";
import DefaultAvatar from "assets/images/avatar_default.png";
import PesoIcon from "assets/images/peso.png";
import PATH from "contants/path";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectIsAuthenticated, selectUsername } from "store/auth";
import { setOpenModal } from "store/modal";
import { selectBalance, selectBalanceLoading, selectProfile } from "store/user";
import { getMemberBalance } from "store/user/user.thunk";
import { ModalTypeValue } from "types/modal";

import BottomSheetCurrency from "../BottomSheetCurrency";
import { HeaderMediumStyle } from "./index.style";
type Props = {
  setIsSidebar: (val: boolean) => void;
};

function HeaderMedium({ setIsSidebar }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const profile = useAppSelector(selectProfile);
  const memberBalance = useAppSelector(selectBalance);
  const username = useAppSelector(selectUsername);
  const isLoading = useAppSelector(selectBalanceLoading);
  const isAuth = useAppSelector(selectIsAuthenticated);

  const [isBottomSheet, setIsBottomSheet] = useState<boolean>(false);

  // useEffect(() => {
  //   if (username) dispatch(getMemberBalance({ username }));
  // }, [username]);

  const copyToClipboard = (txt: string) => {
    navigator.clipboard.writeText(txt);
    messageApi.success("Copy to clipboard success!");
  };

  const navigateAction = (link: string) => {
    navigate(link);
    setIsSidebar(false);
  };
  const openAuthForm = (type: ModalTypeValue) => {
    dispatch(setOpenModal(type));
    setIsSidebar(false);
  };
  return (
    <>
      <div css={HeaderMediumStyle.index}>
        <div css={HeaderMediumStyle.closeBox}>
          <span onClick={() => setIsSidebar(false)}>
            <CloseIconSvg />
          </span>
        </div>
        <div css={HeaderMediumStyle.infoAccount}>
          <div className="avatar">
            <img src={DefaultAvatar} alt="" />
          </div>
          <div className="info">
            {isAuth ? (
              <>
                <span className="full-name">{profile?.username}</span>
                <span
                  onClick={() => copyToClipboard(profile?.username || "")}
                  className="copy"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <CopyIconSvg />
                </span>
              </>
            ) : (
              <>
                <Button
                  onClick={() => openAuthForm(ModalTypeValue.Login)}
                  css={HeaderMediumStyle.login}
                >
                  Login
                </Button>
                <Button
                  onClick={() => openAuthForm(ModalTypeValue.Register)}
                  css={HeaderMediumStyle.register}
                >
                  Register
                </Button>
              </>
            )}
          </div>
        </div>
        {isAuth && (
          <>
            <div css={HeaderMediumStyle.balanceBox}>
              <div
                onClick={() => setIsBottomSheet(true)}
                className="inputValue"
              >
                <Image src={PesoIcon} height={24} width={24} preview={false} />
                <span>{memberBalance?.total}</span>
                <DownIcon className="downIcon" />
              </div>
              <div
                onClick={() => dispatch(getMemberBalance({ username }))}
                className="sync"
              >
                <SyncOutlined spin={isLoading} className="iconProps" />
              </div>
            </div>
            <div css={HeaderMediumStyle.groupButton}>
              <Button
                onClick={() => {
                  navigateAction(PATH.profile.trading);
                }}
                className="deposit"
              >
                <span>Deposit</span>
              </Button>
              <Button
                onClick={() => {
                  navigateAction(PATH.profile.transaction);
                }}
                className="transaction"
              >
                <span>Transaction history</span>
              </Button>
            </div>
          </>
        )}
      </div>
      <BottomSheetCurrency
        balanceTotal={memberBalance?.total}
        visible={isBottomSheet}
        onClose={() => setIsBottomSheet(false)}
      />
      {contextHolder}
    </>
  );
}

export default HeaderMedium;
