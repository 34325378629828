import {
  ImageBGGame,
  ImageDGGame,
  ImageEvolutionGame,
  ImageOGGame,
  ImagePPLiveGame,
  ImageSAGame,
  ImageSXGame,
  ImageWMGame,
} from "assets/images/game/LiveCasino";
import { SubGameType } from "types/game";

export const listGameLiveCasino: SubGameType[] = [
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "SX",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageSXGame,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "DG",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageDGGame,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "OG",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageOGGame,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "SA",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageSAGame,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "WM",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageWMGame,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "EVO",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageEvolutionGame,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "PP",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: ImagePPLiveGame,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "BG",
    type: "LC",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageBGGame,
  },
];
