/** @jsxImportSource @emotion/react */

import { CalendarOutlined } from "@ant-design/icons";
import { Button, List } from "antd";
import DatePickerCustom from "components/base/DatePickerCustom";
import SkeletonCustom from "components/base/SkeletonCustom";
import BettingRecordCard from "components/ui/BettingRecordCard";
import HelmetCustom from "components/ui/Helmet";
import { HeaderUser } from "components/ui/user/HeaderUser";
import { UserWrapperPage } from "components/ui/user/UserWrapperPage";
import { FORMAT_DATE } from "contants/config";
import { listCalendar } from "contants/list-calendar";
import dayjs, { Dayjs } from "dayjs";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import moment from "moment";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { selectUsername } from "store/auth";
import {
  selectDataBettingRecord,
  selectIsLoadingBettingRecord,
  selectPageBettingRecord,
  selectTotalBettingRecord,
  updateCurrentPageBettingRecord,
} from "store/bettingrecord";
import { getWager } from "store/bettingrecord/bettingrecord.thunk";
import { theme } from "styles/theme";

import { PlayRecordPageStyle } from "./style.index";

const PAGE_SIZE = 5;

type State = {
  startDate: Dayjs;
  endDate: Dayjs;
};

const PlayRecordPage = () => {
  const dispatch = useAppDispatch();
  const dataBettingRecord = useAppSelector(selectDataBettingRecord);
  const isLoadingBettingRecord = useAppSelector(selectIsLoadingBettingRecord);
  const totalBettingRecord = useAppSelector(selectTotalBettingRecord);

  const currentPage = useSelector(selectPageBettingRecord);
  const [calendarSelected, setCalendarSelected] = useState(listCalendar[2]);

  const _username =
    useAppSelector(selectUsername) || localStorage.getItem("username") || "";

  const [rangeDate, setRangeDate] = useState<State>({
    startDate: calendarSelected.startDate,
    endDate: calendarSelected.endDate,
  });

  const getDataWager = () => {
    dispatch(
      getWager({
        username: _username,
        page: currentPage,
        size: PAGE_SIZE,
        start: rangeDate.startDate.format("YYYY-MM-DD").toString(),
        end: rangeDate.endDate.format("YYYY-MM-DD").toString(),
      })
    );
  };

  const handleNext = () => {
    if (isLoadingBettingRecord) return;
    dispatch(updateCurrentPageBettingRecord(currentPage + 1));
  };

  const handleSubbmit = () => {
    if (currentPage === 1) {
      getDataWager();
      return;
    }
    dispatch(updateCurrentPageBettingRecord(1));
  };

  const handleOnChangeDate = (item: {
    id: string;
    value: string;
    startDate: Dayjs;
    endDate: Dayjs;
  }) => {
    setCalendarSelected(item);
    setRangeDate({
      startDate: dayjs(item.startDate),
      endDate: dayjs(item.endDate),
    });
  };

  useEffect(() => {
    getDataWager();
  }, [currentPage]);

  return (
    <UserWrapperPage>
      <div css={PlayRecordPageStyle.self}>
        <HelmetCustom title="Betting Record" />
        <HeaderUser title="Betting Record" />
        <div css={PlayRecordPageStyle.blockCalendar}>
          {listCalendar.map((itemCalendar, index) => {
            return (
              <div
                key={`key-${index}-buton-calendar`}
                onClick={() => handleOnChangeDate(itemCalendar)}
                css={PlayRecordPageStyle.buttonCalendar(
                  calendarSelected.id === itemCalendar.id
                )}
              >
                {itemCalendar.value}
              </div>
            );
          })}
        </div>
        <div css={PlayRecordPageStyle.fromGroupContainer}>
          <div css={PlayRecordPageStyle.formGroup}>
            <span css={PlayRecordPageStyle.spanDate(theme.colors.white)}>
              Start Date{" "}
              <span
                css={PlayRecordPageStyle.spanDate(theme.colors.secondary[900])}
              >
                *
              </span>
            </span>
            <DatePickerCustom
              value={rangeDate.startDate}
              format={"DD/MM/YYYY"}
              onChange={(val) => {
                setRangeDate((prev) => ({
                  ...prev,
                  startDate: dayjs(val),
                }));
              }}
              disabledDate={(current) => {
                return current > dayjs().endOf("day");
              }}
              suffixIcon={
                <CalendarOutlined css={PlayRecordPageStyle.iconCalendar} />
              }
            />
          </div>
          <div css={PlayRecordPageStyle.fromDash}>
            <span>To</span>
            <span>To</span>
          </div>

          <div css={PlayRecordPageStyle.formGroup}>
            <span css={PlayRecordPageStyle.spanDate(theme.colors.white)}>
              End Date{" "}
              <span
                css={PlayRecordPageStyle.spanDate(theme.colors.secondary[900])}
              >
                *
              </span>
            </span>
            <DatePickerCustom
              value={rangeDate.endDate}
              format={"DD/MM/YYYY"}
              disabledDate={(current) => {
                return current > dayjs().endOf("day");
              }}
              onChange={(val) => {
                setRangeDate((prev) => ({
                  ...prev,
                  endDate: dayjs(val),
                }));
              }}
              suffixIcon={
                <CalendarOutlined css={PlayRecordPageStyle.iconCalendar} />
              }
            />
          </div>
          <div css={PlayRecordPageStyle.formSubmit}>
            <span css={PlayRecordPageStyle.blockEmpty}>empty</span>
            <Button
              type="primary"
              size="large"
              css={PlayRecordPageStyle.btnSubmit}
              htmlType="submit"
              disabled={isLoadingBettingRecord}
              onClick={handleSubbmit}
            >
              Apply
            </Button>
          </div>
        </div>

        {isLoadingBettingRecord && currentPage === 1 ? (
          <div css={PlayRecordPageStyle.listOrders}>
            {Array.from(Array(1), (_, index) => (
              <SkeletonCustom height="112px" key={index} />
            ))}
          </div>
        ) : (
          <div css={PlayRecordPageStyle.listOrders}>
            <InfiniteScroll
              height={500}
              dataLength={dataBettingRecord?.length}
              next={handleNext}
              hasMore={dataBettingRecord?.length < totalBettingRecord}
              loader={
                isLoadingBettingRecord &&
                Array.from(Array(1), (_, index) => (
                  <SkeletonCustom height="112px" key={index} />
                ))
              }
              endMessage={null}
              scrollableTarget="scrollableDiv"
              css={PlayRecordPageStyle.infinite}
            >
              <List
                dataSource={dataBettingRecord}
                renderItem={(item, index) => (
                  <List.Item key={`item-betting-record-${index}`}>
                    <BettingRecordCard
                      game={item.Game}
                      provider={item.Provider}
                      date={moment(item.Date).format(FORMAT_DATE)}
                      stake={item.Stake}
                      winlose={item.Winlose}
                    />
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </div>
        )}
      </div>
    </UserWrapperPage>
  );
};

export default PlayRecordPage;
