import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const SubGameItemStyle = {
  wrapper: css`
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;

      cursor: pointer;

      transition: 0.2s all;

      &:hover {
        scale: 1.05;
      }

      ${media.sm} {
        &:hover {
          scale: 1;
        }
      }
    }
  `,
};
