/** @jsxImportSource @emotion/react */
import { Button, Progress } from "antd";
import ImageNoHistory from "assets/images/profile/icon-no-history.png";
import { ArrowNextIcon } from "contants/config";
import { useAppSelector } from "hooks/app-hook";
import { selectDataMemberVip } from "store/member";

import { SideBarProfileStyle } from "./style.index";

const SideBarProfile = () => {
  const { data: dataMemberVip } = useAppSelector(selectDataMemberVip);

  return (
    <div css={SideBarProfileStyle.sideBar}>
      <div css={SideBarProfileStyle.sideBarVip.index}>
        <div css={SideBarProfileStyle.sideBarVip.header}>
          Current Level <span>VIP</span>{" "}
          <span>{dataMemberVip.current_level}</span>
        </div>
        <div css={SideBarProfileStyle.sideBarVip.process}>
          <Progress
            type="circle"
            percent={Number(dataMemberVip.next_level.turnover_percentage)}
          />
        </div>
        <div css={SideBarProfileStyle.sideBarVip.textBox}>
          <div css={SideBarProfileStyle.sideBarVip.title}>
            <div css={SideBarProfileStyle.sideBarVip.dots} />
            <span>Deposit Amount</span>
          </div>
          <div css={SideBarProfileStyle.sideBarVip.describe}>
            <p>
              Remaining balance <span>10.3</span> PESO
            </p>
          </div>
        </div>
      </div>
      <div css={SideBarProfileStyle.sideBarRecord.index}>
        <div css={SideBarProfileStyle.sideBarRecord.header}>
          <span>Play Record</span>
          <ArrowNextIcon />
        </div>
        <div css={SideBarProfileStyle.sideBarRecord.content}>
          <img src={ImageNoHistory} alt="" />
          <div css={SideBarProfileStyle.sideBarRecord.notification}>
            <span>You are not currently playing any games</span>
          </div>
          <div css={SideBarProfileStyle.sideBarRecord.btn}>
            <Button type="primary">Go to lobby</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBarProfile;
