import {
  ImageBoongo,
  ImageCQ9,
  ImageFacai,
  ImageHaba,
  ImageIBEX,
  ImageJDB,
  ImageJILI,
  ImageJoker,
  ImageMicrogaming,
  ImageNetend,
  ImagePGSlot,
  ImagePPSlotGame,
  ImageRedTiger,
  ImageRich88,
  ImageRLG,
  ImageSimplePlay,
} from "assets/images/game/Slot";
import { SubGameType } from "types/game";

export const listGameSlots: SubGameType[] = [
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "JILI",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageJILI,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "IBEX",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageIBEX,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "PP",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImagePPSlotGame,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "PGS2",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImagePGSlot,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "FC",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageFacai,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "RLG",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageRLG,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "JK",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageJoker,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "RT",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageRedTiger,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "NE",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageNetend,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "HB",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageHaba,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "CQ9",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageCQ9,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "BNG",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageBoongo,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "JDB",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageJDB,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "RICH",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageRich88,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "MG",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageMicrogaming,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "SP",
    type: "SL",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageSimplePlay,
  },
];
