/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "antd";
import { DepositApi } from "api/deposit";
import { ReactComponent as CardTransfer } from "assets/icons/CardTransfer.svg";
import InputCustom from "components/base/InputCustom";
import Loading from "components/ui/Loading";
import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import _isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import { selectUsername } from "store/auth";
import {
  initialState,
  selectDataDeposit,
  selectIsLoadingDeposit,
} from "store/deposit";
import { getDepositForm } from "store/deposit/deposit.thunk";
import { outletContextType } from "types";
import { DepositOptions } from "types/deposit";
import * as yup from "yup";

import { NextButton } from "../components/StepControl/NextButton";
import { DepositStyle } from "./index.style";

const depositOptions: DepositOptions[] = [
  {
    icon: <CardTransfer />,
    name: "Offline Deposit",
    id: 1,
  },
];
interface DataFormType {
  amount: number;
  promotion_id: string;
  bank_account_id: string;
  currency_id: string;
  username: string;
  type: number;
}
const initDataForm: DataFormType = {
  amount: 0,
  promotion_id: "",
  bank_account_id: "",
  currency_id: "",
  username: "",
  type: 1,
};

interface AmoutType {
  amount: number;
}

//setting
export const Deposit = () => {
  const { openNotification } = useOutletContext<outletContextType>();
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);
  const dataDeposit = useAppSelector(selectDataDeposit);
  const isLoading = useAppSelector(selectIsLoadingDeposit);

  const [dataForm, setDataForm] = useState<DataFormType>(initDataForm);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const { offline_banking_channel, promotion, currencies, setting } =
    dataDeposit;
  const isDataDepositEmpty = _isEqual(initialState.dataDeposit, dataDeposit);

  const schema = () =>
    yup.object({
      amount: yup
        .number()
        .max(
          setting.max_deposit,
          `Max withdraw amount is ${setting.max_deposit}`
        )
        .min(
          setting.min_deposit,
          `Min withdraw amount is ${setting.min_deposit}`
        )
        .transform((value) => (Number.isNaN(value) ? null : value))
        .required("This field is required"),
    });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm<AmoutType>({
    mode: "onChange",
    resolver: yupResolver(schema()),
    defaultValues: {
      amount: 0,
    },
  });

  const isValid = () => {
    if (!dataForm.bank_account_id) return false;
    if (!getValues("amount")) return false;
    if (!dataForm.currency_id) return false;
    return true;
  };

  const onFormSubmit = async (data: AmoutType) => {
    setLoadingSubmit(true);
    const response = await DepositApi.depositSubmission(username, {
      amount: data.amount,
      promotion_id: Number(dataForm.promotion_id || "0"),
      bank_account_id: Number(dataForm.bank_account_id),
      currency_id: Number(dataForm.currency_id),
      username,
      type: 1,
    });
    const { status } = response.data;
    setLoadingSubmit(false);
    if (status !== ResponseCode.SUCCESS) {
      openNotification("error", "Add deposit failed!!!");
      return;
    }
    openNotification("success", "Add deposit success!!!");
    setDataForm({ ...initDataForm, currency_id: currencies[0].currency_id });
    reset();
  };

  useEffect(() => {
    if (!username) return;
    if (!isDataDepositEmpty) return;
    dispatch(getDepositForm(username));
  }, [username]);

  useEffect(() => {
    if (currencies.length) {
      setDataForm((prev) => ({
        ...prev,
        currency_id: currencies[0].currency_id,
      }));
    }
  }, [currencies]);

  return (
    <div css={DepositStyle.index}>
      {isLoading ? (
        <Loading height={100} width={"100%"} center />
      ) : (
        <div css={DepositStyle.content}>
          <div css={DepositStyle.boxOptions}>
            <h3>Deposit options</h3>
            <div css={DepositStyle.listOptions}>
              {depositOptions.map((item, index) => {
                const isActive = item.id === dataForm.type;
                return (
                  <div
                    key={`item-deposit-options-${index}`}
                    css={DepositStyle.itemOptions(isActive)}
                    onClick={() => {
                      if (!isActive) {
                        setDataForm((prev) => ({ ...prev, type: item.id }));
                      }
                    }}
                  >
                    <div className="info">
                      {item.icon}
                      <span>{item.name}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div css={DepositStyle.boxOptions}>
            <h3>Choose a bank</h3>
            <div css={DepositStyle.listOptions}>
              {offline_banking_channel.map((item, index) => {
                const isActive =
                  item.bank_account_id === dataForm.bank_account_id;
                return (
                  <div
                    key={`item-deposit-options-${index}`}
                    css={DepositStyle.itemOptions(isActive)}
                    onClick={() => {
                      if (!isActive)
                        setDataForm((prev) => ({
                          ...prev,
                          bank_account_id: item.bank_account_id,
                        }));
                    }}
                  >
                    <div className="info">
                      <img src={item.picture} alt="" />
                      <span>{item.bank_name}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <Form onSubmitCapture={handleSubmit(onFormSubmit)}>
            <div css={DepositStyle.boxOptions}>
              <h3>Deposit amount</h3>
              <div css={DepositStyle.formInput}>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    height: 100%;
                  `}
                >
                  <InputCustom
                    control={control}
                    name="amount"
                    errors={errors.amount?.message}
                    validate_status={errors.amount && "error"}
                    placeholder="Amount"
                    margin_bottom="0"
                  />
                </div>

                <div css={DepositStyle.listCurrencies}>
                  {currencies.map((item, index) => {
                    const isActive = item.currency_id === dataForm.currency_id;
                    return (
                      <div
                        css={DepositStyle.itemCurrency(isActive)}
                        key={`item-currency-${index}`}
                        onClick={() => {
                          if (!isActive)
                            setDataForm((prev) => ({
                              ...prev,
                              currency_id: item.currency_id,
                            }));
                        }}
                      >
                        <span>{item.currency_name}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div css={DepositStyle.listOptions}>
                {promotion.map((item, index) => {
                  const isActive = item.id === dataForm.promotion_id;
                  return (
                    <div
                      css={DepositStyle.itemPromotion(isActive)}
                      key={`item-promotion-${index}`}
                      onClick={() => {
                        if (!isActive)
                          setDataForm((prev) => ({
                            ...prev,
                            promotion_id: item.id,
                          }));
                      }}
                    >
                      <span>{item.title}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div css={DepositStyle.boxOptions}>
              <NextButton
                text="Complete"
                disabled={!isValid()}
                loading={loadingSubmit}
              />
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};
