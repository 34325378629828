/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Button } from "antd";
import { media } from "styles/breakpoints";

interface Props {
  onBackClick(): void;
}

export const PreviousButton = (props: Props) => {
  const { onBackClick } = props;
  return (
    <Button
      css={css`
        width: 30%;
        background-color: #101520;
        border: 1px solid #ffb31a;
        height: 38px;
        font-size: 16px;
        border-radius: 30px;
        color: #ffb31a;
        margin-bottom: 16px;

        &:hover {
          color: #ffb31a !important;
          border-color: transparent !important;
          transform: scale(1.05);
        }

        ${media.sm} {
          width: 100%;
        }
      `}
      onClick={onBackClick}
    >
      Previous step
    </Button>
  );
};
