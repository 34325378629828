import {
  Image188BetGame,
  ImageAFB88Game,
  ImageBTIGame,
  ImageCMDGame,
  ImageIBCGame,
} from "assets/images/game/sports";
import { SubGameType } from "types/game";

export const listGameSports: SubGameType[] = [
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "IBC",
    type: "SB",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageIBCGame,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "CMD",
    type: "SB",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageCMDGame,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "AFB88",
    type: "SB",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageAFB88Game,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "188Bet",
    type: "SB",
    featured: "NO",
    new_arrive: "NO",
    picture: Image188BetGame,
  },
  {
    game_code: "0",
    name: "Lobby",
    provider_code: "BTI",
    type: "SB",
    featured: "NO",
    new_arrive: "NO",
    picture: ImageBTIGame,
  },
];
