import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const HeaderStyles = {
  container: (isHeader: boolean) => css`
    align-items: center;
    background-color: #070b1c;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 0 4rem;
    width: 100%;

    ${media.lg} {
      padding: 0 2rem;
    }

    ${media.md} {
      padding: 0.25rem 0.9375rem;
      display: ${isHeader ? "flex" : "none"};
    }
  `,
  header_left: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  logoApp: css`
    width: 9rem;
    cursor: pointer;
    padding: 1rem 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  `,
  header_right: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  `,
  button: (index: number) => css`
    background-color: ${index === 1
      ? theme.colors.neutrals[400]
      : theme.colors.secondary[900]};
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    padding: 0.3125rem 0.9375rem;
    margin-left: 0.495rem;

    P {
      font-size: ${theme.fontSize[14]};
      color: ${theme.colors.white};
      line-height: 1.375rem;
      font-weight: 400;
    }
  `,
  depositButton: css`
    button {
      height: 44px;
      padding: 0 24px;
      background-color: ${theme.colors.secondary[900]};
      border: 0px;
      border-radius: 22px;
      transition: 0.1s all;

      ${media.md} {
        display: none;
      }

      &:hover {
        color: ${theme.colors.white} !important;
        transform: scale(1.05);
      }

      span {
        font-weight: 500;
      }
    }
  `,
  btnSearch: () => css`
    cursor: pointer;
    width: 48px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${media.md} {
      display: none;
    }
  `,
};
