import { ReactComponent as IconAbout } from "assets/images/profile/icon-profile-about.svg";
import { ReactComponent as IconMyBet } from "assets/images/profile/icon-profile-bet.svg";
import { ReactComponent as IconPersonalFavorites } from "assets/images/profile/icon-profile-collection.svg";
import { ReactComponent as IconDeposit } from "assets/images/profile/icon-profile-deposit_1.svg";
import { ReactComponent as IconDepositRecord } from "assets/images/profile/icon-profile-deposit-record.svg";
import { ReactComponent as IconApp } from "assets/images/profile/icon-profile-download.svg";
import { ReactComponent as IconNotification } from "assets/images/profile/icon-profile-notification.svg";
import { ReactComponent as IconEditPassword } from "assets/images/profile/icon-profile-password.svg";
import { ReactComponent as IconPromotions } from "assets/images/profile/icon-profile-promotion.svg";
import { ReactComponent as IconPersonalRebate } from "assets/images/profile/icon-profile-rebate_1.svg";
import { ReactComponent as IconTeamSharing } from "assets/images/profile/icon-profile-share.svg";
import { ReactComponent as IconPersonalVip } from "assets/images/profile/icon-profile-vip.svg";
import { ReactComponent as IconWithdrawal } from "assets/images/profile/icon-profile-withdraw_1.svg";
import { ReactComponent as IconWithdrawalRecord } from "assets/images/profile/icon-profile-withdraw-record.svg";

import { LinkDownloadFileApk } from "./apk";
import PATH from "./path";

export interface TypeFunctionListItem {
  name: string;
  image: React.FunctionComponent;
  id: string;
}

export interface TypeFunctionList {
  title: string;
  key: number;
  content: TypeFunctionListItem[];
}

export const FUNCTION_LIST: TypeFunctionList[] = [
  {
    title: "Personal",
    key: 1,
    content: [
      {
        image: IconPersonalVip,
        name: "My Vip",
        id: PATH.profile.myVip,
      },
      {
        image: IconPersonalFavorites,
        name: "My Favorites",
        id: PATH.myCollectionPage,
      },
      {
        image: IconPersonalRebate,
        name: "Rebate",
        id: `${PATH.profile.transaction}?type=3`,
      },
      {
        image: IconPromotions,
        name: "Promotions",
        id: PATH.promotionPage,
      },
    ],
  },

  {
    title: "Auxiliary",
    key: 2,
    content: [
      {
        image: IconApp,
        name: "Download APP",
        id: LinkDownloadFileApk,
      },
      {
        image: IconAbout,
        name: "About",
        id: "",
      },
      {
        image: IconNotification,
        name: "Notification Center",
        id: PATH.profile.notificationCenter,
      },
    ],
  },
  {
    title: "Transactions",
    key: 3,
    content: [
      {
        image: IconMyBet,
        name: "My bet",
        id: PATH.profile.myBet,
      },
      {
        image: IconEditPassword,
        name: "Edit Password",
        id: "change-password",
      },
      {
        image: IconTeamSharing,
        name: "Friend Sharing",
        id: PATH.profile.friendSharing,
      },
    ],
  },
  {
    title: "Record",
    key: 4,
    content: [
      {
        image: IconDeposit,
        name: "Deposit",
        id: PATH.profile.trading,
      },
      {
        image: IconWithdrawal,
        name: "Withdrawal",
        id: `${PATH.profile.trading}?type=2`,
      },
      {
        image: IconDepositRecord,
        name: "Deposit Record",
        id: PATH.profile.transaction,
      },
      {
        image: IconWithdrawalRecord,
        name: "Withdrawal Record",
        id: `${PATH.profile.transaction}?type=2`,
      },
    ],
  },
];
